.privacy {
    margin: 10px;
    padding-bottom: 50px;
}

.privacy_title {
    font-weight: bold;
}

.privacy span {
    display: block;
    /* margin: 13px; */
    font-size: 14px;
    line-height: 28px;
}

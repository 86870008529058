.home {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #4edec3;
}

.home_link {
    /* height: 70px; */
    display: flex;
    justify-content: flex-end;
    margin: 20px 50px 0 0;
}

.home_link span {
    cursor: pointer;
    font-size: 13px;
}

.home_header {
    background-color: #4edec3;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    /* padding-left: 400px; */
}

.home_logo {
    width: 140px;
    height: 140px;
    border: white 2px solid;
    border-radius: 20px;
    margin-right: 50px;
}

.home_title {
    display: flex;
    flex-direction: column;
}

.home_title span:nth-child(1) {
    font-size: 62px;
    letter-spacing: 5px;
}
.home_title span:nth-child(2) {
    font-size: 22px;
    margin-top: 10px;
    letter-spacing: 5px;
}

/* 特点介绍 */
.home_content {
    background-color: #f9f8fb;
    padding: 40px 0;
}

.home_img {
    display: flex;
    justify-content: space-around;
    padding: 0 300px;
}

.home_img_item {
    width: 230px;
    /* margin-left: 500px; */
}

/* 下载区 */
.home_download {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    background-color: white;
}

.home_download button {
    height: 50px;
    width: 150px;
    background-color: #4edec3;
    color: white;
    border-style: none;
    border-radius: 50px;
    font-size: 15px;
    margin: 0 30px;
    cursor: pointer;
}

/* 页脚 */
.home_footer {
    background-color: #f0f1f2;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
    font-size: 13px;
}

.home_footer a {
    color: gray;
    font-size: 13px;
    text-decoration: none;
}
